h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

.modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: clamp(100px, 90vw, 35rem);
    /* width: 35rem; */
    background-color: white;
    padding: 1.5em;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.row-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-input-container, .emails-list {
    width: 100%;
    border: solid 1px black;
    border-radius: 10px;
    overflow: hidden;
}

.tabs {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.tab {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: large;
    position: relative;
    cursor: pointer;
    padding: 0.2em 0;
    border-bottom: solid 1px black;
}

.active {
    font-weight: 600;
    border-bottom: solid 1px #007bff;
    color: #007bff;
}

.active::after {
    position: absolute;
    content: '';
    height: 2px;
    width: 100%;
    background-color: #007bff;
    bottom: 0;
}

.droppable-area {
    border: dashed #007bff 2px;
    border-radius: 10px;
    height: 13rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.droppable-area > h6 {
    display: flex;
    gap: 1ch;
}

.modal-input {
    padding: 1em;
}

.browse-files-link {
    color: #d5eaff;
    color: #007bff;
    cursor: pointer;
}

.supported-extenstions {
    color: rgb(150, 150, 150);
}

.email {
    padding: 0.2em 1em;
}

.email.dark { 
    background-color: #d5eaff;
}

button {
    cursor: pointer;
}

.email-input-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.email-input {
    width: 90%;
    padding-left: 0.5em;
    border: 2px dashed #007bff;
}

.email-input:focus {
    border-color: #0069d9 I !important;
}

.email-add-btn {
    color: #007bff;
    cursor: pointer;
    transition: color 0.3s;
}

.email-add-btn:hover {
    color: #0069d9;
}

.emails-list {
    overflow-y: auto;
    max-height: 10rem;
}

.file-name {
    margin: 0.5em 0;
    font-size: 1.1em;
    font-weight: 600;
}

.add-to-course-btn {
    font-size: larger;
}