.features {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    /* min-height: calc(100vh - 40px); */
    margin: 100px 0;
    flex-wrap: wrap;
    align-content: space-evenly;
    row-gap: 50px;
}

.feature-card {
    padding-top: clamp(15vw, 50%, 10rem);
    width: 500px;
    /* height: 400px; */
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
    border-radius: 10px;
    overflow: hidden;
    background-position: center;
    container-type: inline-size;
}

.feature-card-title {
    position: relative;
    width: max-content;
    font-size: clamp(1rem, 7cqi, 2rem);
}

.feature-card-title::after {
    content: "";
    position: absolute;
    bottom: -7px;
    right: 0;
    width: calc(100% + 1.5rem);
    height: min(1.2cqi, 4px);
    background-color: #e5a823;
}

.feature-card-content {
    color: white;
    padding: 1.5em;
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.5) 20%, rgba(0, 0, 0, 1));
}

.academic-goals-feature {
    background-image: url(../../public/images/student-4311761_1920.jpg);
}

.career-planning-feature {
    background-position: left;
    background-image: url(../../public/images/stairs-5957112_1920_Copy.jpg);
}

.feature-card {
    transition: transform 0.5s ease;
    box-shadow: 5px 5px 10px rgb(131, 131, 131);
}

.feature-card:hover .feature-card-title::after {
    transform: scaleX(1);
}

.feature-card:hover .feature-card-content {
    transform: translateY(0%);
    transition-delay: 0.5s;
}

.feature-card:hover .feature-card-content *:not(.feature-card-title) {
    opacity: 1;
}

.feature-card-desc {
    padding-left: 15px;
    margin: 20px 0 0;
    font-size: clamp(0.7rem, 5cqi, 1rem);
}

.feature-card-desc li::marker {
    color: #008cd8;
}

@media only screen and (max-width: 650px) {
    .features {
      width: 100%;
    }

    .feature-card {
        width: 75%;
    }
}

@media (hover :hover) {
    .feature-card:hover {
        transform: scale(1.05);
    }

    .feature-card-title::after {
        transform: scaleX(0);
        transform-origin: left;
        transition: transform 0.5s ease;
    }

    .feature-card-content {
        transform: translateY(55%);
        transition: transform 0.5s ease;
    }

    .feature-card-content *:not(.feature-card-title) {
        opacity: 0;
        transition: opacity 0.5s linear;
    } 
}