.tab-heading {
    background-color: #f7f7f7;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: clamp(1.1rem, 8.5vw, 2rem);
    font-weight: 600;
    padding: var(--padding-vertical) var(--padding-horizontal);
    animation: fade-in 0.3s;
}

.tab-container {
    width: 100%;
    height: 100%;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}