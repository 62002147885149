td{
    /* background-color: rgb(255, 255, 255); */
    color: rgb(3, 98, 223);
    font-weight: 350;
    padding: 2px;
    margin: 1px;
}

.modal-overlay {
  position: absolute;
  z-index: 9;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: #ffffff;
  border: 1px solid #bebebe;
  border-radius: 2px;
  padding: 12px 16px;
}

.item_role{
    color: black;
}

table {
    border-collapse: collapse;
    width: 100%;
  }

  .sp{
    height: 20px !important;
    overflow-y: 'scroll' !important;
  }
  
  th, td {
    text-align: left;
    padding: 8px;
  }
  
  tr:nth-child(even) {background-color: #84818160;}

/* li:nth-child(odd) {
    background-color: rgba(105, 103, 103, 0.351);
    padding: 2px;
    margin: 1px;
} */

/* For On Hover functionalities */

.likes__wrapper{
  display:flex;
  align-content:center;
  justify-content:center;
   
}
.likes__relavance{
  position:relative;
  /* padding:0 80px; */
}

.likes__list{
  position:absolute;
  box-sizing: border-box;
  overflow-y:scroll;
  max-height:150px;
  left:0%;
  z-index:999;
  background:white;
  padding:10px;
  border:1px solid grey;
  box-shadow: 0 0 2px 2px grey;
}