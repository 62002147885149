.announcement-tile {
    background-color: white;
    overflow: hidden;
    min-height: 150px;
    width: 100%;
    display: flex;
    font-weight: 400;
    font-size: 1.2rem;
    transition: transform 0.3s, box-shadow 0.3s;
    box-shadow: 3px 3px 5px rgb(158 158 158/ 75%);
    border-radius: 5px;
    text-decoration: none !important;
    color: black;
    animation: fade-in 0.3s;
    position: relative;
}

.announcement-more-menu {
    position: absolute;
    top: 1em;
    right: 1em;
    cursor: pointer;
}

.announcement-top-container, .announcement-description, .announcement-date-container {
    padding: 1rem;
}

.announcement-tile:hover {
    /* font-weight: 500; */
    box-shadow: 4px 4px 7px rgb(158 158 158/ 75%);
    /* transform: scale(1.05); */
    text-decoration: none !important;
}

.announcement-date-container {
    background-color: #1976d2;
    color: white;
    min-width: max-content;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-weight: 500;
    gap: 0.5em;
}

.announcement-date {
    text-align: right;
}

.announcement-title {
    font-weight: 600;
    font-size: clamp(1.6rem, 6vw, 2rem);
}

.announcement-creator-name {
    font-size: 1.1rem;
    font-style: oblique;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}