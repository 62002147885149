:root {
    --padding-horizontal: max(1.2rem, 3vw);
    --padding-vertical: 0.5rem;
}

.main-container {
    min-height: 80dvh;
}

.main-heading {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: clamp(1.4rem, 12vw, 3rem);
    font-weight: 600;
    padding: 0 var(--padding-horizontal) var(--padding-vertical);
}

.main-heading-skeleton {
    font-size: clamp(1.4rem, 12vw, 3rem);
    font-weight: 600;
    width: 20ch;
}