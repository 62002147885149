.navbar-brand {
    display: flex;
    color: white;
}

.user-name {
    margin: 0;
}

.navbar-text, .user-name {
    position: relative;
}

.navbar-text:hover, .navbar-brand:hover, .user-name:hover {
    color: white;
    text-decoration: none;
}

.navbar-text::after, .user-name::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 0.1em;
    background-color: white;
    transition: width 300ms, transform 300ms;
}

.user-name:hover::after,
.navbar-text:hover::after,
.navbar-text:focus::after{
    width: 100%;
    transform: translate3d(0, 0, 0);
}

.nav-links {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 15px;
}