h1, h2, h3, h4, h5, h6, p {
    margin: 0;
}

.modal-heading {
    font-size: clamp(2em, 10vw, 3em);
}

.h3 {
    font-size: clamp(1.3em, 6vw, 2em);
}

.default-text {
    color: hsla(0, 0%, 0%, 0.5);
    font-size: clamp(1em, 4vw, 1.3em);
}

.modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: clamp(100px, 90vw, 60rem);
    /* width: 35rem; */
    background-color: white;
    padding: 1.5em;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    gap: 1em;
    max-height: 90vh;
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.row-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-input-container, .emails-list {
    width: 100%;
    border: solid 1px black;
    border-radius: 10px;
    overflow: auto;
    padding: 1.5em;
    display: grid;
    gap: 1em;
}

.tabs {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.tab {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: large;
    position: relative;
    cursor: pointer;
    padding: 0.2em 0;
    border-bottom: solid 1px black;
}

.active {
    font-weight: 600;
    border-bottom: solid 1px #007bff;
    color: #007bff;
}

.active::after {
    position: absolute;
    content: '';
    height: 2px;
    width: 100%;
    background-color: #007bff;
    bottom: 0;
}

.droppable-area {
    border: dashed #007bff 2px;
    border-radius: 10px;
    height: 13rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.droppable-area > h6 {
    display: flex;
    gap: 1ch;
}

.modal-input {
    padding: 1em;
}

.browse-files-link {
    color: #d5eaff;
    color: #007bff;
    cursor: pointer;
}

.supported-extenstions {
    color: rgb(150, 150, 150);
}

.email {
    padding: 0.2em 1em;
}

.email.dark { 
    background-color: #d5eaff;
}

button {
    cursor: pointer;
}

.email-input-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.email-input {
    width: 90%;
    padding-left: 0.5em;
    border: 2px dashed #007bff;
}

.email-input:focus {
    border-color: #0069d9 I !important;
}

.email-add-btn {
    color: #007bff;
    cursor: pointer;
    transition: color 0.3s;
}

.email-add-btn:hover {
    color: #0069d9;
}

.emails-list {
    overflow-y: auto;
    max-height: 10rem;
}

.file-name {
    margin: 0.5em 0;
    font-size: 1.1em;
    font-weight: 600;
}

.add-to-course-btn {
    font-size: larger;
}

.create-question-container {
    border: solid 1px;
    border-radius: 4px;
    width: 100%;
    border-color: rgba(0, 0, 0, 0.23);
    padding: 16.5px 14px;
    cursor: pointer;
    display: grid;
    gap: 1em;
    grid-template-columns: 1fr 10fr;
}

.create-question-container:hover {
    border-color: black;
}

.questions-list {
    display: grid;
    gap: 1em;
}

.create-question-form {
    border: dashed 2px #1976d2;
    border-radius: 4px;
    padding: 1em;
    display: grid;
    gap: 1em;
    justify-items: start;
}

.question-options, .result-options {
    display: grid;
    gap: 10px;
    width: 100%;
}

.option, .add-option-button {
    border: solid 1px;
    border-radius: 4px;
    width: 100%;
    border-color: rgba(0, 0, 0, 0.23);
    padding: 1em 1em;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-left: 0;
    font-size: 1rem;
    font-weight: 400;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.option:focus-within {
    border-color: #1976d2;
    border-width: 2px;
}

.option.error {
    border-color: #d32f2f;
    border-width: 2px;
}

.option-text {
    -webkit-text-fill-color: black !important;
    color: black !important;
}

.question-option:not(.selected):hover {
    background-color: #3b85ca;
    color: white;
}

.timer-container {
    border: solid 1px;
    border-radius: 4px;
    border-color: rgba(0, 0, 0, 0.23);
    padding: 0 0.5em 0.5em 0.5em;
    display: flex;
    gap: 0.5em;
    width: 40%;
}

.timer-container > legend {
    width: auto;
    font-size: 0.9em;
}

.option.selected {
    background-color: #67b86b23;
    border-color: #2e7d32;
    border-width: 2px;
}

.add-option-button {
    border-radius: 4px;
    border: #1976d2 dashed 2px;
    padding: 1em;
    display: flex;
    align-items: center;
    gap: 1em;
    font-size: 1rem;
    font-weight: 400;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    cursor: pointer;
}

.add-option-button:hover {
    background-color: rgb(208, 233, 254);
}

.error-helper-text {
    color: #d32f2f;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
}

.gapped-flex {
    display: flex;
    gap: 1em;
}

.flex-space-between {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

@media only screen and (min-width: 750px) {
    .question-options, .result-options {
        grid-template-columns: 1fr 1fr;
    }   
}

@media only screen and (max-width: 550px) {
    .create-quiz-btns, .to-col{
        flex-direction: column;
        gap: 1em;
    }
}