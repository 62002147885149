.files-container {
    width: 30%;
    resize: horizontal;
    overflow: auto;
}

.syllabus-container {
    width: 70%;
    resize: horizontal;
    overflow: auto;
    /* padding: var(--padding-vertical) var(--padding-horizontal); */
}

.container {
    height: 100%;
    display: flex;
    background-color: #f7f7f7;
}

.save-btn {
    align-self: flex-end;
}

.container-heading {
    padding: var(--padding-vertical) var(--padding-horizontal);
    font-size: clamp(1rem, 8.2vw, 1.8rem);
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: space-between;
    animation: fade-in 0.3s;
}

.main-text-container {
    display: flex;
    flex-direction: column;
    padding: 1rem var(--padding-horizontal);
    gap: 1rem;
}

.files {
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 1em 1em;
    background-color: #e7e7e7;
}

.file {
    cursor: pointer;
    width: 100%;
    height: 75px;
    border-radius: 5px;
    background-color: white;
    box-shadow: 3px 3px 5px rgb(158 158 158/ 75%);
    display: flex;
    align-items: center;
    padding: 0 1rem;
    justify-content: space-between;
    transition: transform 0.3s, box-shadow 0.3s;
}

.file:hover {
    font-weight: 500;
    box-shadow: 4px 4px 7px rgb(158 158 158/ 75%);
    transform: scale(1.01);
    text-decoration: none !important;
}

.file-icon {
    height: 60%;
    width: initial;
}

.file-icon-title-container {
    display: flex;
    gap: 1rem;
    height: 100%;
    width: auto;
    align-items: center;
}

@media only screen and (max-width: 1024px) {
    .container {
        flex-direction: column;
    }

    .files-container {
        width: 100%;
    }
    
    .syllabus-container {
        width: 100%;
    }
}