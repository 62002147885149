

@media (max-width:1000px) {
    #myTable {
      overflow-x: scroll;
      display: block;
    }

    .category-input {
        width: 120px;
    }

    .grade-slider {
        max-width: 80px;
        width: auto;
    }

    .grade-input {
        max-width: 50px;
        width: auto;
        min-width: auto;
    }

    .table-num-input {
        min-width: 50px;
        max-width: 100px;
    }

    .feature-container {
        padding: 0;
        margin: 10px;
        max-width: -webkit-fill-available;
    }
}