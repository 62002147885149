.container {
    height: 100dvh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: clamp(100px, 90vw, 80rem);
    border: none;
}

/* .container {
    background-color: white;
    padding: 1.5em;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    gap: 1em;
    max-height: 90vh;
} */