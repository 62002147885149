
.report {
    font-size: 1.9rem;
}
  
.report > * {
    margin: 15px 0;
}
  
.report-heading {
    text-align: center;
    padding: 3px;
    background-color: #0055A2;
    color: white;
    border: 1px solid black;
}

.report-meter-color-box {
    width: 50px;
    margin: 10px 0
}

.report-meter-desc {
    text-align: left;
    padding: 5px 10px;
    font-weight: bold;
    font-size: 0.75em;
}

.report-table {
    min-width: 70%;
    font-size: 1.5rem;
}

.report-table td {
    text-align: start;
    padding-top: 10px;
}

.report-table thead {
    background-color: lightgray;
}