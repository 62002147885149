.main-container {
    background-color: #efefef;
}

.courses-list-container {
    padding: var(--padding-vertical) var(--padding-horizontal); 
    display: grid;
    padding-top: 1em;
    gap: 1em;
    grid-template-columns: repeat(5, 1fr);
}

.course {
    background-color: white;
    cursor: pointer;
    padding: 1rem;
    display: flex;
    align-items: flex-end;
    font-weight: 400;
    font-size: 1.2rem;
    transition: transform 0.3s, box-shadow 0.3s, background-color 0.3s;
    box-shadow: 3px 3px 5px rgb(158 158 158/ 75%);
    border-radius: 5px;
    text-decoration: none !important;
    color: black;
    animation: fade-in 0.3s;
}

.course:hover {
    font-weight: 500;
    box-shadow: 4px 4px 7px rgb(158 158 158/ 75%);
    transform: scale(1.05);
    text-decoration: none !important;
}

.skeleton-card, .course {
    height: 125px;
}

a {
    text-decoration: none !important;
}

.tabs {
    padding: 0 var(--padding-horizontal);
    background-color: white;
}

/* 
.tab {
    padding: 0 !important;
    margin: 0 var(--padding-horizontal) !important;
} 
*/

.tab:focus-visible, .tab:focus {
    outline: none;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media only screen and (max-width: 1250px) {
    .courses-list-container {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media only screen and (max-width: 1000px) {
    .courses-list-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (max-width: 750px) {
    .courses-list-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 500px) {
    .courses-list-container {
        grid-template-columns: repeat(1, 1fr);
    }
}