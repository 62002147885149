.modal-size {
    position: absolute;
    width: 100%;
    height: 100%;
  
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;

    transform: translate(-50%, -50%);
  }

.box-size {
    width: 100%;
    height: 100%;
}