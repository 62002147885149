.quiz {
    display: grid;
    gap: clamp(15px, 5vw, 50px);
    padding: clamp(30px, 5vw, 50px);
}

.quiz-container {
    min-height: 80vh;
}

.quiz-sidebar-container {
    display: flex;
    flex-direction: column;
    gap: clamp(15px, 5vw, 50px);
    align-items: center;
    position: relative;
}

.question-nav-btn {
    font-size: cla;
}

.quiz-button {
    cursor: pointer;
    padding: 0.2em 1em;
    border-radius: 10px;
    font-size: 1.5rem;
    background-color: #0055A2;
    color: white;
    /* width: auto; */
}

.quiz-button:hover {
    background-color: #3b85ca;
}

.score {
    display: flex;
    align-items: end;
}

.quiz-title {
    font-weight: bolder;
    font-size: clamp(1rem, 7vw, 2.5rem);
    color: #0055A2;
    text-align: center;
}

.question-index {
    font-size: clamp(1rem, 6vw, 1.75rem);
}

.question, .result {
    display: grid;
    row-gap: 30px;
}

.question-header, .result-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.question-points, .result-points, .question-instruction, .result-instruction {
    font-size: clamp(0.8rem, 4.2vw, 1.5rem);
}

.question-nav-btn {
    color: #0055A2;
    cursor: pointer;
    font-size: clamp(0.8rem, 4vw, 1.25rem);
}

.row-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.question-options, .result-options {
    display: grid;
    gap: 10px;
}

.question-option-front, .result-option-front {
    border-radius: 10px;
    border: 2px solid #0055A2;
    font-size: large;
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: clamp(0.9rem, 3vw, 0.98rem);
}

.question-option-front, .result-option-front {
    background-color: white;
    transform: translateY(-6px);
    transition: transform 0.2s;
    z-index: 100;
    position: relative;
    height: 100%;
    width: 100%;
    overflow-wrap: anywhere;
}

.question-option-front {
    cursor: pointer;
}

.question-option, .result-option {
    position: relative;
    background-color: #0055A2;
    border-radius: 10px;
    width: 100%;
}

.question-option:not(.selected) .question-option-front:hover {
    background-color: #badeff;
}

.quiz-timer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-size: clamp(0.8rem, 5.5vw, 1.5rem);
}

.question-option.selected .question-option-front, .result-option.selected .result-option-front {
    transform: translateY(-3px);
    background-color: #468cce;
    color: white;
}

.add-possible-answer-btn {
    align-self: center;
}

/* .question-option-background {
    background-color: #0055A2;
    height: 100%;
} */

.questions-palette {
    padding: 1em;
    gap: 1em;
    display: grid;
    background-color: #e0e0e0;
    border-radius: 10px;
    text-align: center;
    width: 100%;
}

.questions-nav-panel {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 15px;
}

.question-icon {
    aspect-ratio: 1;
    background-color: #8d8d8d;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10%;
    cursor: pointer;
    font-size: large;
    flex-direction: column;
}

.attempted {
    background-color: #0055A2;
}

.question-icon.isRunning:not(.attempted):hover {
    background-color: #797979;
}

.result-option.correct .result-option-front {
    color: #18603d;
    background-color: #e3eee4;
    border-color: #18603d;
}

.result-option.correct.selected .result-option-front {
    color: white;
    background-color: #2e7d32;
    border-color: #18603d;
}

.result-option.correct {
    background-color: #18603d;
    border-color: #18603d;
}

.correct {
    color: white;
    background-color: #2e7d32;
    border-color: #2e7d32;
}

.result-option.incorrect .result-option-front {
    color: #fff;
    background-color: #e52e34;
    border-color: #8a0b0f;
}

.result-option.incorrect {
    background-color: #8a0b0f;
    border-color: #8a0b0f;
}

.incorrect {
    color: white;
    background-color: #d32027;
    border-color: #d32027;
}

.blank, .blank:focus-visible, .result-blank, .result-blank:focus-visible {
    border: none;
    border-bottom: #0055A2 solid;
    text-align: center;
    border-radius: 0;
    background-color: transparent;
    outline: none;
    pointer-events: none;
}

.blank, .blank:focus-visible {
    width: 10ch;
}

.correct-blank, .correct-blank:focus-visible {
    border-color: #2e7d32;
}

.incorrect-blank, .incorrect-blank:focus-visible {
    border-color: #d32027;
    text-decoration: line-through;
}


.blank-inputs {
    display: flex;
    flex-direction: column;
    gap: 10px;
}


.modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: clamp(100px, 90vw, 35em);
    /* width: 35rem; */
    background-color: white;
    padding: 1.5em;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 1em;
}

.explanation {
    padding: 1em;
    width: 100%;
    background-color: #e3eee4;
    border: #2e7d32 solid 2px;
    border-radius: 10px;
    display: grid;
    gap: 1em;
    font-size: clamp(0.9rem, 3vw, 1rem);
}

.explanation h5 {
    color: #2e7d32;
}

.h4 {
    font-size: clamp(0.8rem, 5vw, 1.5rem);
}

.quiz-info {
    text-align: center;
}

.quiz-main-container {
    display: grid;
    gap: 30px;
}

.current {
    font-weight: bolder;
    border-radius: 25%;
    transition: border-radius 0.3s;
}

@media only screen and (min-width: 750px) {
    .quiz {
        grid-template-columns: 4fr 1fr;
    }

    .progress {
        grid-column: 1 / span 2;
    }
    
    .quiz-title, .quiz-info {
        text-align: left;
    }

    .quiz-main-container {
        grid-column: 1;
        grid-row: 3;
    }
    
    .quiz-sidebar-container {
        grid-column: 2;
    }

    .question-options, .result-options {
        grid-template-columns: 1fr 1fr;
    }

    .current {
        font-weight: bold;
        z-index: 100;
    }
}

@media only screen and (max-width: 750px) {
    .questions-palette h4 {
        display: none;
    }
    
    .questions-palette {
        padding: 0.5em;
    }
    
    .questions-nav-panel {
        gap: 0.5em;
        display: flex;
        overflow-x: auto;
        overflow-y: hidden;
        height: clamp(30px, 10vw, 40px);
    }

    .question-icon {
        aspect-ratio: 1;
        
        height: 100%;
    }
}

.disabled {
    pointer-events: none;
    color: grey;
    opacity: 50%;
}