.announcements-list {
    display: flex;
    flex-direction: column-reverse;
    gap: 1em;
    padding: 1em var(--padding-horizontal);
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}