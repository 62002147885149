.form-control {
    /* min-height: 5.5rem; */
    
  
  }

.large-text-field {
  height: 200px; /* Adjust the height as needed */
    padding: 10px; /* Add padding to align text to top-left */
    padding-top: 0px !important;
    resize: none; /* Prevent text area resizing by users */
    overflow-y: auto; /* Add vertical scroll if content exceeds height */
}

.large-form {
  width: 1000px; /* Set the desired width */
  padding: 20px; /* Add padding for spacing */
  border: 0px solid #ccc; /* Add a border for visual separation */
  border-radius: 8px; /* Add rounded corners */
  background-color: rgba(0, 0, 0, 0.3); /* Set a background color */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}