.container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: clamp(100px, 90vw, 60rem);
    background-color: white;
    padding: 1.5em;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    gap: 1em;
    max-height: 90vh;
}

.top-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.modal-heading {
    font-size: clamp(2em, 10vw, 3em);
}

.button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1em;   
}