* {
    box-sizing: border-box;
}

img {
    display: inline-block;
    width: 100%;
}

html {
    scroll-behavior: smooth;
}

body {
    padding: 0;
    padding-top: 120px;
    margin: 0;
    background: rgb(247, 247, 247);
}

td,
tr,
th {
    padding: 2px 5px;
    border-bottom: none !important;
}

table {
    border-collapse: collapse;
}

input {
    border-radius: 5px;
    border: 2px solid #33aaff;
}

td,
th {
    text-align: center;
}

tr {
    border-top: none;
    border-collapse: collapse;
}

/* span {
    display: block;
} */

#myTable {
    border-collapse: collapse;
    width: 100%;
}

nav {
    background-color: #0055a2;
    color: #fff;
    padding: 30px 60px;
    display: flex;
    justify-content: space-between;
}

nav ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

nav li {
    list-style: none;
}

nav .navbar-item a,
nav .navbar-item span {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.2rem;
    padding: 5px 8px;
}

nav .navbar-item a:hover,
nav .navbar-item span:hover,
nav .navbar-item button:hover {
    color: #f7ca18;
    text-decoration: none;
}

.no-border {
    border-width: 0;
}

.header {
    min-height: 95vh;
    background-size: cover;
    background-position: center;
    position: relative;
}

.main-container {
    min-height: 80vh;
    /* padding-bottom: 200px; */
}

.input-max-400 {
    max-width: 400px;
    width: 100%;
}

.category-input {
    font-weight: bold;
}

.grade-slider {
    width: -webkit-fill-available;
}

.grade-input {
    min-width: 80px;
}

.loader-container {
    position: fixed;
    top: 100px;
    left: 50%;
    transform: translate(-50%, 0);
    flex-wrap: nowrap;
    z-index: 20;
    padding: 5px;
    border-radius: 5%;
    background-color: rgba(208, 224, 199, 0.486);
}

.loader {
    border: 8px solid #a1af9c;
    /* Light grey */
    border-top: 8px solid #454545;
    /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loader-message {
    align-self: center;
    padding: 0px 10px;
    font-size: 1.2rem;
    font-weight: bold;
    text-shadow: 0px 2px #eeffe8;
    color: #000;
    white-space: nowrap;
}

.avatar-ctn {
    background-color: rgb(215, 216, 218);
    text-align: center;
}

.avatar {
    height: 150px;
    width: auto;
    max-width: 300px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

/*--- navigation bar ---*/
.navbar {
    background: #0055a2;
    position: fixed;
    top: 0;
    width: 100%;
    transition: 0.2s;
    z-index: 10;
}

.hyperlink-button {
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    padding: 5px 8px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.text-center {
    text-align: center;
}

#nav-logo {
    transition: 0.2s;
}

/* .nav-link,
.navbar-brand {
    color: #fff;
    cursor: pointer;
    display: flex;
}

.nav-link,
.navbar-brand:hover {
    color: #f7ca18;
} */

.nav-link {
    margin-right: 1em !important;
}

.nav-link:hover {
    color: #f7ca18;
}

.navbar-collapse {
    justify-content: flex-end;
}

.description {
    left: 50%;
    position: absolute;
    top: 25%;
    transform: translate(-50%, -55%);
    text-align: center;
}

.description h1 {
    color: #6ab446;
}

.description p {
    color: #fff;
    font-size: 1.3rem;
    line-height: 1.5;
}

.feature-container {
    padding: 0 80px;
    display: flex;
    flex-direction: column;
    border: none;
    background: none;
}

.feature-container:focus {
    outline: none;
}

.feature-tile {
    height: 100%;
    background-color: #0055a2;
}

.feature-tile:hover {
    height: 100%;
    background-color: #0055a2;
    color: #f7ca18;
}

.features {
    margin: 4em auto;
    padding: 1em;
    position: relative;
}

.feature-title {
    font-size: 1.3rem;
    font-weight: 700;
    margin-bottom: 20px;
    text-transform: uppercase;
}

.features img {
    -webkit-box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
    margin-bottom: 16px;
}

.feature-image-container {
    max-width: 400px;
}

.feature-bullets {
    text-align: left;
}

.page-footer {
    background-color: #03417a;
    color: rgb(247, 243, 243);
    padding: 60px 0 30px;
    position: relative;
    clear: both;
}

.footer-copyright {
    color: rgb(248, 245, 245);
    padding: 40px 0;
}

.footer-copyright a {
    color: white;
}

.meter-container {
    justify-content: space-evenly;
}

.meter-division-value {
    min-width: 130px;
    text-align: center;
    border-radius: 5%;
    padding: 3px 5px;
}

.bg-red {
    background-color: #fc1e14;
}

.bg-yellow {
    background-color: #f7c82f;
}

.bg-green {
    background-color: #24ab24;
}

.white-text {
    color: white;
}

.form-group {
    flex-grow: 1;
    margin-right: 20px;
}

.form-control {
    margin-top: 0.5rem;
}

.form-input-invalid {
    background-color: #ffdddd;
}

.form-input-valid {
    background-color: #ddffdd;
}

.form-input-required {
    border-color: #e63f3f;
    border-width: 0 0 3px 0;
    border-radius: 0;
}

#message {
    min-height: 150px;
}

.superscript {
    font-size: 1.2rem;
}

.subscript {
    font-size: 0.8rem;
}

.modal-body {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400;
    border: 2px solid #000;
}

.error {
    color: #d32f2f;
}

.login-col + .login-col {
    border-left: solid 1px black;
}

.form-container{
    min-height: 80vh;
    margin-left: 31rem;
    margin-right: 31rem;
    padding: 10px;
}

.form-sub-container{
    background-color: white;
    padding: 2rem;
    margin-top: 4rem;
    border-radius: 5px;
    border: 0.5px solid rgb(214, 214, 214);
}

.submit-btn{
    background-color: #0055a2;
    color: white;
    text-align: center;
    margin-top: 10px;
    padding:5px;
    border-radius: 5px;
}

.cluster{
    display: flex;
    margin-top: 1rem;
}

.submit-btn:hover{
    background-color: #33aaff;
}


.inp2{
    margin-left: 30px;
    width: 15rem;
}

.inp1{
    margin-left: 10px;
    width: 15rem;
}