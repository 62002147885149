.course-top-wrapper {
    padding: 0 15%;
    display: flex;
    justify-content: space-between;
}

.course-card-container {
    padding: 0 15%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 100%;
    gap: 1.5em;
}

.page {
    background-color: rgb(245, 245, 245);
}

.course-card {
    background-color: white;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 3px 3px 6px gray;
    width: 100%;
    height: max(125px, 15vh);
    padding: 0.5em;
    padding-left: calc(0.5em + 10px);
    font-size: 1.3em;
    font-weight: 500;
    display: flex;
    align-items: flex-end;
    position: relative;
    overflow: hidden;
    transition: color 0.3s;
}

.course-card:hover {
    transition: transform 0.3s;
    /* color: #1976d2; */
    transform: scale(1.05);
    box-shadow: 5px 5px 10px gray;
}

.course-card:hover .course-name{
    transition: color 0.3s;
    color: white;
    transition: transform 0.3s;
    text-decoration: none;
    transform-origin: left bottom;
    transform: scale(1.15);
}

.course-card::before {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    height: 100%;
    width: 100%;
    background-color: #1976d2;
    transform: translateX(calc(10px - 100%));
    transition: background-color 0.3s;
    transition: transform 0.3s;
}

.course-card:hover::before {
    background-color: #1976d2;
    transform: translateX(0);
}

.course-name {
    text-decoration: none;
    z-index: 10;
    transition: color 0.3s;
}

.course-name:hover {
    text-decoration: none;
    z-index: 10;
    transition: color 0.2s;
}