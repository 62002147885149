.display {
    margin: 10px;
    padding: 10px;
    min-height: 400px;
    min-width: 100%;
    display: block;
}

.sketch-options-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

.sketch-item {
    margin: 10px
}

.course-top-wrapper {
    display: flex;
    justify-content: space-between;
}

.set-flex {
    display: flex;
}

.render-wrapper {

    border: 1px solid silver;
    padding: 10px;
    min-width: 100%;
    min-height: 70vh;
    box-shadow: 2px 2px 5px grey;
    text-align: center;
}

.add-moderator-wrapper {
    text-align: left;
}

.pallette-selectors {
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 5px
}

.shapes-item-icon {
    padding: 3px;
    color: blue;
    box-shadow: 1px 1px 5px silver;
}

.shapes-item-icon:hover {
    padding: 3px;
    background-color: blue;
    color: white;
    transition: ease-in 0.5s;
    box-shadow: 1px 1px 5px silver;
}