@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@media print {
	body {
		margin: 0 !important;
	}
}

.main-ck-container {
	font-family: 'Lato';
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}

.ck-content {
	font-family: 'Lato';
	line-height: 1.6;
	word-break: break-word;
}

.question-input {
    border: none;
    border-bottom: 1px solid black;
	text-align: center;
	pointer-events: none;
	cursor: pointer;
	width: clamp(10ch, 20vw, 15ch);
}

.question-input.focused {
	background-color: #ddedff;
	border-color: #1976d2;
	color: #1976d2;
	transition: background-color 0.3s;
	border-width: 2px;
}

.question-input.focused::placeholder {
	color: #1976d2;
	opacity: 1; 
	transition: opacity 0.3s;
	transition: color 0.3s;
	transition: border 0.3s;
}
  
.question-input.focused::-ms-input-placeholder { /* Edge 12 -18 */
	color: #1976d2;
	transition: color 0.3s;
	transition: border 0.3s;
}

.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
	box-shadow: none;
	border-color: #1976d2;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	border-width: 2px;
}

.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content, .ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content.ck-rounded-corners {
	border-radius: 4px;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.ck-placeholder {
	width: 16ch;
}

.ck-placeholder::after {
	color: #d32027;
	position: absolute;
    right: 0;
	top: 0;
	content: '*';
}

figure.image img {
	height: auto !important;
}

/* .ck-body-wrapper {
    opacity: 0;
} */