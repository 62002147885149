h1, h2, h3, h4, h5, h6, p {
    margin: 0;
}

.modal-heading {
    font-size: clamp(2em, 10vw, 3em);
}

.h3 {
    font-size: clamp(1.3em, 6vw, 2em);
}

.default-text {
    color: hsla(0, 0%, 0%, 0.5);
    font-size: clamp(1em, 4vw, 1.3em);
}

.modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: clamp(100px, 90vw, 60rem);
    /* width: 35rem; */
    background-color: white;
    padding: 1.5em;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    gap: 1em;
    max-height: 90vh;
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.row-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-input-container, .emails-list {
    width: 100%;
    border: solid 1px black;
    border-radius: 10px;
    overflow: auto;
    padding: 1.5em;
    display: grid;
    gap: 1em;
}

@media only screen and (max-width: 550px) {
    .create-quiz-btns, .to-col{
        flex-direction: column;
        gap: 1em;
    }
}