.accnt {
    text-align: center;
}

.gradient-custom {
    background: #0055a2;

    background: -webkit-linear-gradient(to right bottom, #0055a2, #0055a2);

    background: linear-gradient(to right bottom, #0055a2, #0055a2);
}

.t_img {
    height: fit-content !important;
    width: fit-content !important;
}

.link1 {
    color: white;
    text-decoration: none;
    padding: 10px;
    /* font-size: 1.4rem;  */
}

.link1:hover {
    color: yellow;
    text-decoration: none;
}
