
.files {
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 1em 1em;
    background-color: #e7e7e7;
}

.file {
    cursor: pointer;
    width: 100%;
    height: 75px;
    border-radius: 5px;
    background-color: white;
    box-shadow: 3px 3px 5px rgb(158 158 158/ 75%);
    display: flex;
    align-items: center;
    padding: 0 1rem;
    justify-content: space-between;
    transition: transform 0.3s, box-shadow 0.3s;
}

.file:hover {
    font-weight: 500;
    box-shadow: 4px 4px 7px rgb(158 158 158/ 75%);
    transform: scale(1.01);
    text-decoration: none !important;
}

.file-icon {
    height: 60%;
    width: initial;
}

.file-icon-title-container {
    display: flex;
    gap: 1rem;
    height: 100%;
    width: auto;
    align-items: center;
}

.container {
    height: 100dvh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: clamp(100px, 90vw, 80rem);
    border: none;
}

.container-bg-white{
    background: #fff;
}