.quizzes-container {
    display: flex;
    gap: 1em;
    flex-direction: column;
    padding: 1em var(--padding-horizontal);
}

.quiz {
    border-radius: 5px;
    padding: 1em 1em;
    box-shadow: 3px 3px 5px rgb(158 158 158/ 75%);
    background-color: white;
    transition: transform 0.3s, box-shadow 0.3s;
}

.quiz:hover {
    font-weight: 500;
    box-shadow: 4px 4px 7px rgb(158 158 158/ 75%);
    transform: scale(1.01);
    text-decoration: none !important;
}
